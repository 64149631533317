@import '../../../styles/customMediaQueries.css';

.sectionAboutUs {
  background-color: #ffffff;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 50px 0;
  width: 90%;
  margin: auto;
}

.leftColumn {
  width: 50%;
  text-align: center;
}

.image {
  width: 474px;
  height: auto;
  max-width: 100%;
}

.rightColumn {
  width: 50%;
  padding: 20px;
  justify-content: center;
}

.header {
  font-weight: 400;
  color: #00deea;
  line-height: 21px;
  font-size: medium;
}

.title {
  font-weight: 500;
  color: #1c2635;
  line-height: 45px;
  font-size: x-large;
}

.description {
  font-weight: 400;
  color: #4a4a4a;
  line-height: 24px;
  font-size: medium;
}

.ctaButton {
  color: #1c2635;
}

.ctaButtonText {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #ffffff;
  color: #1c2635;
  width: 190px;
  height: 56px;
  border-radius: 4px;
  border: 1px solid #1c2635;
  margin: 20px 0 0 8px;
}

/* Mobile layout */
@media only screen and (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .leftColumn {
    width: 100%;
  }

  .rightColumn {
    width: 100%;
    padding: 20px 0;
  }
}