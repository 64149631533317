@import '../../../styles/customMediaQueries.css';

.sectionEasyProcesses {}

p {
  margin: 8px;
}

.container {
  display: flex;
  flex-direction: row;
  padding: 50px 0;
  width: 90%;
  margin: auto;
}

.leftDiv {
  flex: 1;

  @media(--viewportMedium) {
    max-width: 435px;
    padding: 0 30px 0 0;
  }
}

.rightDiv {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.sectionContainer {
  display: flex;
  align-items: center;
  background-color: #f7f7f7;
  margin: 10px 0;
  padding: 0 17px;
  border: 1px solid;
  border-radius: 6px;
  border-color: #e0e0e0;
}

.sectionContainer img {
  width: 50px;
  height: auto;
  margin-right: 20px;
  align-self: flex-start;
  padding-top: 12px;
}

.sectionContainer .text {
  flex-grow: 1;
}

/* on mobile view */
@media only screen and (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .leftDiv {
    order: 1;
  }

  .rightDiv {
    order: 2;
  }

  .sectionContainer {
    width: 100%;
  }
}

.header {
  font-weight: 400;
  color: #00DEEA;
  line-height: 21px;
  font-size: medium;
}

.title {
  font-weight: 500;
  color: #1C2635;
  line-height: 45px;
  font-size: x-large;
}

.description {
  font-weight: 400;
  color: #4A4A4A;
  line-height: 24px;
  font-size: medium;
}

.image {
  width: 50px;
  height: 50px;
}