.sectionJoinTheMasters {
  position: relative;
}

.sectionJoinTheMasters::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../../../assets/joinTheMasters.png');
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.sectionJoinTheMasters:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1C2635;
  opacity: 87%;
  z-index: -1;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 300px;
  overflow: hidden;
  width: 90%;
  margin: auto;
  padding: 50px 0;
}

.header {
  color: #ffffff;
  margin-bottom: 1rem;
  position: relative;
}

.description {
  color: #ffffff;
  margin-bottom: 1.5rem;
  position: relative;
}

.ctaButton {
  padding: 0.5rem 1rem;
  background-color: #00DEEA;
  color: #1C2635;
  font-size: small;
  font-weight: 500;
  width: 190px;
  height: 56px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}