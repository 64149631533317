@import '../../../styles/customMediaQueries.css';

.sectionOurServices {
  background-color: #FFFFFF;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  width: 90%;
  margin: auto;
}

.imagesContainer {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
}

.imageItem {
  flex: 1;
  margin: 0 10px;

  @media (--viewportMedium) {
    flex: unset;
    margin: 0;
  }

  @media (--viewportLarge) {
    flex: 1 1;
  }
}

.imageItem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .imageItem {
    flex-basis: calc((100%/2) - 20px);
  }
}

.ctaButton {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.headerText {
  text-align: center;
  text-justify: inter-word;
  margin-bottom: 20px;
}

.header {
  font-weight: 400;
  color: #00DEEA;
  line-height: 21px;
  font-size: medium;
}

.title {
  font-weight: 500;
  color: #1C2635;
  line-height: 45px;
  font-size: x-large;
}

.description {
  font-weight: 400;
  color: #4A4A4A;
  line-height: 24px;
  font-size: medium;
}

.cta {
  justify-self: center;
  font-size: small;
  color: #00DEEA;
  font-weight: 500;
  margin: 2px 0;
  padding-top: 0;
}

.serviceTitle {
  font-size: medium;
  color: #1C2635;
  font-weight: 500;
  justify-self: center;
  margin: 5px 0;
}

.ctaButton {
  margin-top: 20px;
  padding: 10px 20px;
  width: 190px;
  height: 56px;
  font-size: small;
  font-weight: 500;
  color: #1C2635;
  background-color: #00DEEA;
  cursor: pointer;
  border-radius: 4px;
  border: none;
}