@import '../../../styles/customMediaQueries.css';

.sectionServices {
  background-color: #FFFFFF;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  width: 90%;
  margin: auto;
}

.headerText {
  text-align: center;
  text-justify: inter-word;
  margin-bottom: 20px;
}

.header {
  font-weight: 400;
  color: #00DEEA;
  line-height: 21px;
  font-size: medium;
}

.title {
  font-weight: 500;
  color: #1C2635;
  line-height: 45px;
  font-size: x-large;
}

.description {
  font-weight: 400;
  color: #4A4A4A;
  line-height: 24px;
  font-size: medium;
}