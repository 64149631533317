@import '../../../styles/customMediaQueries.css';

.sectionPricing {
  background-color: #00DEEA;
}

.container {
  padding: 50px 0;
  width: 90%;
  margin: auto;
}

.content {
  @media (--viewportLargeWithPaddings) {
    display: flex;
    gap: 50px;
  }
}

.headerSection {
  margin-bottom: 40px;

  @media (--viewportLargeWithPaddings) {
    width: 400px;
  }
}

.subheading {
  font-size: small;
  font-weight: 400;
  line-height: 21px;
  color: #1C2635;
  margin-bottom: 10px;
}

.heading {
  font-size: x-large;
  font-weight: 500;
  line-height: 45px;
  color: #1C2635;
  margin-bottom: 15px;
}

.description {
  font-size: medium;
  font-weight: 400;
  line-height: 24px;
  color: #1C2635;
  margin: auto;
}

.cardContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 30px;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.card1,
.card2 {
  background-color: #e0e0e0;
  border-radius: 8px;
  padding: 30px;

  @media (--viewportMedium) {
    width: calc(100%/2);
  }
}

.card2 {
  background-color: #1C2635;
  color: white;
}

.cardTitle {
  font-size: large;
  font-weight: 600;
  margin-bottom: 15px;
}

.cardPrice {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 20px;
}

.cardDescription {
  font-size: small;
  font-weight: 400;
  margin-bottom: 30px;
  line-height: 21px;
}

.cardFeatures {
  list-style-type: none;
  padding: 0;
  margin-bottom: 30px;
  list-style-type: disc;
  padding-left: 20px;
}

.cardFeatures li {
  font-size: small;
  font-weight: 400;
  line-height: 21px;
  padding: 0;
}

.cardButton {
  background-color: #00DEEA;
  color: #1C2635;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  margin-top: auto;
  text-align: center;
  text-decoration: none;
  display: block;
  box-sizing: border-box;
}

.card1 .cardButton:hover {
  background-color: #00c5d0;
  text-decoration: none;
}

.card2 .cardButton {
  background-color: white;
}

.card2 .cardButton:disabled {
  color: #25496C;
  background-color: #1C2635;
  opacity: 0.5;
  cursor: not-allowed;
  border: 1px solid #25496C;
}

.crown {
  width: 24px;
  margin-left: 8px;
}

.reviewsIcon {
  width: 171px;
  margin: 12px auto;
}

.ratingsIcon {
  width: 300px;
}

@media (max-width: 768px) {
  .content {
    flex-direction: column;
  }

  .card1,
  .card2 {
    width: 100%;
  }
}