@import '../../../styles/customMediaQueries.css';

.customHeroSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  background-image: url('../../../assets/heroBanner.jpeg');
  opacity: 80%;
  padding: 0;
}

.customHeroSection>div {
  padding-bottom: 0px;

  @media (--viewportLarge) {
    padding-bottom: 64px;
  }

  @media (min-width: 1275px) {
    padding: 120px 0;
  }
}

.customHeroSection::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(circle at top left,
      rgba(227, 0, 177, 0.6) 20%,
      transparent 20%);
  filter: blur(5px);
}

.contentContainer {
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media (--viewportLarge) {
    flex-direction: row;
    align-items: center;
  }
}

.leftColumn {
  color: var(--colorWhite);
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media (--viewportMedium) {
    width: 100%;
  }

  @media (--viewportLarge) {
    width: calc(100% / 2);
  }
}

.textLarge {
  font-size: 24px;
  font-weight: 700;
}

.textSmall {
  font-size: 12px;
  font-weight: 400;
}

.linkBtn:hover {
  text-decoration: unset;
}

.customButton {
  display: flex;
  width: 190px;
  padding: 10px 40px;
  justify-content: center;
  align-items: center;
  color: #1C2635;
}

.rightColumn {
  padding-bottom: 0;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media (--viewportMedium) {
    width: 100%;
    padding: 0px;
  }

  @media (--viewportLarge) {
    position: absolute;
    bottom: 0;
    right: -50px;
    width: clamp(40.625rem, 29.9107rem + 16.7411vw, 50rem);
  }

  @media (--viewportLargeWithPaddings) {
    right: 0;
  }
}

.heroImage {
  object-fit: cover;
  width: 100%;
  position: relative;
  left: -20px;

  @media (--viewportLarge) {
    position: unset;
    left: unset;
  }
}

.formContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}