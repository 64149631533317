@import '../../../styles/customMediaQueries.css';

.sectionFeatures {
  background-color: #f7f7f7;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 50px 0;
  width: 90%;
  margin: auto;

  @media (--viewportLarge) {
    gap: 30px;
  }
}

.headerText {
  text-align: left;
}

.header {
  font-weight: 400;
  color: #00DEEA;
  line-height: 21px;
  font-size: medium;
}

.title {
  font-weight: 500;
  color: #1C2635;
  line-height: 45px;
  font-size: x-large;
}

.description {
  font-weight: 400;
  color: #4A4A4A;
  line-height: 24px;
  font-size: medium;
}


.featuresContainer {
  display: flex;
  flex-direction: column;
}

.featureRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
}

@media (max-width: 768px) {
  .featureRow {
    flex-direction: column;
    margin-bottom: auto;
  }

  .featureItem {
    width: 100%;
    margin: 16px 0;
  }
}

.featureItem {
  flex-basis: 48%;
  background-color: #ffffff;
  padding: 16px;
  border-radius: 8px;
  display: flex;
}

.featureImage {
  margin-right: 20px;
}

.image {
  width: 70px;
}

.featureContent {
  display: flex;
  flex-direction: column;
}

.featureTitle {
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.featureDescription {
  color: #4A4A4A;
  font-size: medium;
  margin: 0;
  padding: 0;
  font-weight: 400;
}