@import '../../../styles/customMediaQueries.css';

.sectionBuildYourEvent {}

.container {
  margin: 0;
  padding: 50px 0;
  width: 90%;
  margin: auto;
}

.imagesContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.imagesContainer img {
  width: calc((100% / 2) - 20px);
  height: auto;

  @media(--viewportLarge) {
    width: calc((100% / 4) - 15px);
  }
}

.image {
  width: 265px;
  height: 171px;
}